<template>
  <div class="article__wrapper">
    <header>
      <h1 class="article__header">
        <nuxt-link to="/">
          <img
            src="/img/top/logo.svg"
            width="223"
            height="19"
            alt=""
            class="article__header-img"
          />
        </nuxt-link>
      </h1>
    </header>
    <slot />
    <footer class="article__footer">
      <div class="article__footer__container">
        <ul class="article__footer__links">
          <nuxt-link
            to="/articles"
            class="article__footer__link"
            @click="scrollToTop"
          >
            WARCエージェント マガジン
          </nuxt-link>
          <nuxt-link
            to="https://corp.warc.jp/privacy-policy/"
            target="_blank"
            class="article__footer__link"
          >
            プライバシーポリシー
          </nuxt-link>
          <nuxt-link
            to="https://corp.warc.jp/"
            target="_blank"
            class="article__footer__link"
          >
            会社概要
          </nuxt-link>
          <nuxt-link to="/" class="article__footer__link">
            WARCエージェント
          </nuxt-link>
        </ul>
        <div class="article__footer__item">
          <nuxt-link to="/" class="article__footer__logo">
            <img
              src="/img/top/logo.svg"
              width="223"
              height="19"
              alt=""
              class="article__header-img"
            />
          </nuxt-link>
          <p class="article__footer__description">
            <!-- サイトの説明文が入ります。 -->
          </p>
        </div>
      </div>
      <span class="article__footer__copy">&copy; WARC.inc</span>
    </footer>
  </div>
</template>

<script setup lang="ts">
const scrollToTop = () => {
  window.scrollTo({ top: 0 });
};
</script>
